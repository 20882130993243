import isPlainObject from 'is-plain-obj'

import { IMutationTree, ITrackStateTree, TTree } from './types'

export const IS_PROXY = Symbol('IS_PROXY')
export const PATH = Symbol('PATH')
export const VALUE = Symbol('VALUE')
export const PROXY_TREE = Symbol('PROXY_TREE')

const arrayMutations = new Set([
  'push',
  'shift',
  'pop',
  'unshift',
  'splice',
  'reverse',
  'sort',
  'copyWithin',
])

const getValue = (proxyOrValue) =>
  proxyOrValue && proxyOrValue[IS_PROXY] ? proxyOrValue[VALUE] : proxyOrValue

const isClass = (value) => typeof value === 'object' && value !== null && !Array.isArray(value) && value.constructor.name !== 'Object' && Object.isExtensible(value)

const getArrayMutationRevert = (method: string, target: any[], args: any[]) => {
  switch (method) {
    case 'push': {
      return () => {
        target.splice(target.indexOf(args[0]), 1)
      }
    }
    case 'shift': {
      const value = target[0]
      return () => {
        target.unshift(value)
      }
    }
    case 'pop': {
      const index = target.length - 1
      const value = target[index]

      return () => {
        target.splice(index, 1, value)
      }
    }
    case 'unshift': {
      return () => {
        target.splice(target.indexOf(args[0]), 1)
      }
    }
    case 'splice': {
      const removedValues = target.slice(args[0], args[1])
      const addedValues = args.slice(2)
      return () =>
        target.splice(args[0], args[1] + addedValues.length, ...removedValues)
    }
    case 'reverse': {
      return () => target.reverse()
    }
    case 'sort': {
      return () => target.sort((a, b) => -1 * args[0](a, b))
    }
    case 'copyWithin': {
      return () => target
    }
  }
}

export class Proxifier {
  CACHED_PROXY = Symbol('CACHED_PROXY')
  delimiter: string
  constructor(private tree: TTree) {
    this.delimiter = tree.master.options.delimiter
  }
  private concat(path, prop) {
    return path ? path + this.delimiter + prop : prop
  }

  ensureMutationTrackingIsEnabled(path) {
    if (process.env.NODE_ENV === 'production') return

    if (this.tree.master.options.devmode && !this.tree.canMutate()) {
      throw new Error(
        `proxy-state-tree - You are mutating the path "${path}", but it is not allowed. The following could have happened:
        
        - The mutation is explicitly being blocket
        - You are passing state to a 3rd party tool trying to manipulate the state
        - You are running asynchronous code and forgot to "await" its execution
        `
      )
    }
  }

  isDefaultProxifier() {
    return this.tree.proxifier === this.tree.master.proxifier
  }

  ensureValueDosntExistInStateTreeElsewhere(value) {
    if (process.env.NODE_ENV === 'production') return

    if (value && value[IS_PROXY] === true) {
      throw new Error(
        `proxy-state-tree - You are trying to insert a value that already exists in the state tree on path "${
          value[PATH]
        }"`
      )
    }

    return value
  }

  trackPath(path: string) {
    if (!this.tree.canTrack()) {
      return
    }

    if (this.isDefaultProxifier()) {
      const trackStateTree = this.tree.master.currentTree as ITrackStateTree<
        any
      >

      if (!trackStateTree) {
        return
      }

      trackStateTree.addTrackingPath(path)
    } else {
      ;(this.tree as ITrackStateTree<any>).addTrackingPath(path)
    }
  }
  // With tracking trees we want to ensure that we are always
  // on the currently tracked tree. This ensures when we access
  // a tracking proxy that is not part of the current tracking tree (pass as prop)
  // we move the ownership to the current tracker
  getTrackingTree() {
    if (this.tree.master.currentTree && this.isDefaultProxifier()) {
      return this.tree.master.currentTree
    }

    if (!this.tree.canTrack()) {
      return null
    }

    if (this.tree.canTrack()) {
      return this.tree
    }

    return null
  }
  getMutationTree() {
    return this.tree.master.mutationTree || (this.tree as IMutationTree<any>)
  }
  private isProxyCached(value, path) {
    return (
      value[this.CACHED_PROXY] &&
      String(value[this.CACHED_PROXY][PATH]) === String(path)
    )
  }
  private createArrayProxy(value, path) {
    if (this.isProxyCached(value, path)) {
      return value[this.CACHED_PROXY]
    }

    const proxifier = this

    const proxy = new Proxy(value, {
      get(target, prop) {
        if (prop === IS_PROXY) return true
        if (prop === PATH) return path
        if (prop === VALUE) return value
        if (prop === 'indexOf') {
          return (searchTerm, offset) =>
            value.indexOf(getValue(searchTerm), getValue(offset))
        }
        if (
          prop === 'length' ||
          (typeof target[prop] === 'function' &&
            !arrayMutations.has(String(prop))) ||
          typeof prop === 'symbol' || target[prop] instanceof Date
        ) {
          return target[prop]
        }

        const trackingTree = proxifier.getTrackingTree()
        const nestedPath = proxifier.concat(path, prop)
        const currentTree = trackingTree || proxifier.tree

        trackingTree && trackingTree.proxifier.trackPath(nestedPath)
        currentTree.trackPathListeners.forEach((cb) => cb(nestedPath))

        const method = String(prop)

        if (arrayMutations.has(method)) {
          /* @__PURE__ */ proxifier.ensureMutationTrackingIsEnabled(nestedPath)
          return (...args) => {
            const mutationTree = proxifier.getMutationTree()

            let result


            if (process.env.NODE_ENV === 'production') {
              result =  target[prop](...args)
            } else {
              result = target[prop](
                ...args.map((arg) =>
                  /* @__PURE__ */ proxifier.ensureValueDosntExistInStateTreeElsewhere(
                    arg
                  )
                )
              )
            }

            mutationTree.addMutation({
              method,
              path: path,
              delimiter: proxifier.delimiter,
              args: args,
              hasChangedValue: true,
              revert: getArrayMutationRevert(method, proxy, args),
            })

            return result
          }
        }

        if (target[prop] === undefined) {
          return undefined
        }

        return proxifier.proxify(target[prop], nestedPath)
      },
      set(target, prop, value) {
        const nestedPath = proxifier.concat(path, prop)

        /* @__PURE__ */ proxifier.ensureMutationTrackingIsEnabled(nestedPath)
        /* @__PURE__ */ proxifier.ensureValueDosntExistInStateTreeElsewhere(
          value
        )

        const mutationTree = proxifier.getMutationTree()
        const existingValue = target[prop]
        const result = Reflect.set(target, prop, value)

        mutationTree.addMutation({
          method: 'set',
          path: nestedPath,
          args: [value],
          delimiter: proxifier.delimiter,
          hasChangedValue: true,
          revert: () => {
            if (existingValue === undefined) {
              delete proxy[prop]
            } else {
              proxy[prop] = existingValue
            }
          },
        })

        return result
      },
    })

    Object.defineProperty(value, this.CACHED_PROXY, {
      value: proxy,
      configurable: true,
    })

    return proxy
  }

  private createObjectProxy(object, path) {
    if (this.isProxyCached(object, path)) {
      return object[this.CACHED_PROXY]
    }

    const proxifier = this

    const proxy = new Proxy(object, {
      get(target, prop) {
        if (prop === IS_PROXY) return true
        if (prop === PATH) return path
        if (prop === VALUE) return object
        if (prop === PROXY_TREE) return proxifier.tree

        if (typeof prop === 'symbol' || prop in Object.prototype || target[prop] instanceof Date)
          return target[prop]

        const descriptor = Object.getOwnPropertyDescriptor(target, prop) || Object.getOwnPropertyDescriptor(Object.getPrototypeOf(target), prop)

        if (descriptor && 'get' in descriptor) {
          const value = descriptor.get.call(proxy)

          if (
            proxifier.tree.master.options.devmode &&
            proxifier.tree.master.options.onGetter
          ) {
            proxifier.tree.master.options.onGetter(
              proxifier.concat(path, prop),
              value
            )
          }

          return value
        }

        const trackingTree = proxifier.getTrackingTree()
        const targetValue = target[prop]
        const nestedPath = proxifier.concat(path, prop)
        const currentTree = trackingTree || proxifier.tree

        if (typeof targetValue === 'function' && isClass(target)) {
          return (...args) => targetValue.call(proxy, ...args)
        } else if (typeof targetValue === 'function') {
          if (proxifier.tree.master.options.onFunction) {
            const { func, value } = proxifier.tree.master.options.onFunction(
              trackingTree || proxifier.tree,
              nestedPath,
              targetValue
            )
            
            target[prop] = func

            return value
          }
          return targetValue.call(target, proxifier.tree, nestedPath)
        } else {
          currentTree.trackPathListeners.forEach((cb) => cb(nestedPath))
          trackingTree && trackingTree.proxifier.trackPath(nestedPath)
        }

        if (targetValue === undefined) {
          return undefined
        }

        return proxifier.proxify(targetValue, nestedPath)
      },
      set(target, prop, value) {
        const nestedPath = proxifier.concat(path, prop)

        /* @__PURE__ */ proxifier.ensureMutationTrackingIsEnabled(nestedPath)
        /* @__PURE__ */ proxifier.ensureValueDosntExistInStateTreeElsewhere(
          value
        )

        let objectChangePath

        if (!(prop in target)) {
          objectChangePath = path
        }

        const mutationTree = proxifier.getMutationTree()
        const existingValue = target[prop]

        if (typeof value === 'function' && proxifier.tree.master.options.onFunction) {
          const result = proxifier.tree.master.options.onFunction(
            proxifier.getTrackingTree() || proxifier.tree,
            nestedPath,
            value
          )
          
          value = result.func
        }

        const hasChangedValue = value !== target[prop]
        const result = Reflect.set(target, prop, value)

        mutationTree.addMutation(
          {
            method: 'set',
            path: nestedPath,
            args: [value],
            delimiter: proxifier.delimiter,
            hasChangedValue,
            revert: () => {
              if (existingValue === undefined) {
                delete proxy[prop]
              } else {
                proxy[prop] = existingValue
              }
            },
          },
          objectChangePath
        )

        return result
      },
      deleteProperty(target, prop) {
        const nestedPath = proxifier.concat(path, prop)

        /* @__PURE__ */ proxifier.ensureMutationTrackingIsEnabled(nestedPath)

        let objectChangePath
        if (prop in target) {
          objectChangePath = path
        }

        const mutationTree = proxifier.getMutationTree()
        const existingValue = target[prop]


        delete target[prop]

        mutationTree.addMutation(
          {
            method: 'unset',
            path: nestedPath,
            args: [],
            delimiter: proxifier.delimiter,
            hasChangedValue: true,
            revert: () => {
              proxy[prop] = existingValue
            },
          },
          objectChangePath
        )

        return true
      },
    })

    Object.defineProperty(object, this.CACHED_PROXY, {
      value: proxy,
      configurable: true,
    })

    return proxy
  }
  proxify(value: any, path: string) {
    if (value) {
      const isUnmatchingProxy =
        value[IS_PROXY] &&
        (String(value[PATH]) !== String(path) ||
          value[VALUE][this.CACHED_PROXY] !== value)

      if (isUnmatchingProxy) {
        return this.proxify(value[VALUE], path)
      } else if (value[IS_PROXY]) {
        return value
      } else if (Array.isArray(value)) {
        return this.createArrayProxy(value, path)
      } else if (isPlainObject(value) || isClass(value)) {
        return this.createObjectProxy(value, path)
      } 
    }

    return value
  }
}
